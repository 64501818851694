<template>
    <DSelect
        v-model="accessGroups"
        size="large"
        apiUrl="/contractor_permissions/access_groups/"
        class="w-full"
        multiple
        infinity        
        :maxTagCount="1"
        usePopupContainer
        :getPContainer="getPopupContainer"
        :listObject="false"
        labelKey="name"
        @change="changeHandler"
        :placeholder="$t('sports.selectFromList')"
        :default-active-first-option="false"
        :filter-option="false"
        :not-found-content="null" />
</template>


<script>
import DSelect from '@apps/DrawerSelect/Select.vue'
export default {
    components: {
        DSelect
    },
    props: {
        record: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            accessGroups: []
        }
    },
    methods: {
        getPopupContainer() {
            return document.body
        },
        changeHandler(value) {
            const url = 'contractor_permissions/access_groups/set_user/'
            const payload = {
                user: this.record.user.id,
                contractor: this.record.organization.id,
                access_groups: value,
            }
            this.$http.post(url, payload)
                .catch(error => {
                    console.error(error)
                    this.$message.error('Не удалось установить права доступа')
                })
        },
    }
}
</script>